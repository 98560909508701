@import './_core/tailwind/base.css';
@import './_core/tailwind/components.css';
@import './_core/tailwind/utilities.css';

:root {
    --toastify-z-index: 99999999999 !important;
}

@font-face {
    font-family: iransans;
    src: url('/public/fonts/iransans/IRANSansWeb.woff2');
    font-display: block;
}

@font-face {
    font-family: iransans_without_numbers;
    src: url('/public/fonts/iransans/IRANSansWeb.woff2');
    font-display: block;
    unicode-range: U+0600-06FF, U+FB50-FDFF, U+FE70-FEFF;
}

.iransans_without_numbers_class,
.iransans_without_numbers_class input {
    font-family: iransans_without_numbers, Calibri !important;
}

html,
body {
    height: 100dvh;
    direction: rtl;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: iransans, sans-serif !important;
}

textarea {
    overflow-y: scroll !important;
}

#root {
    height: 100%;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
}

.react-datepicker-popper {
    z-index: 1000 !important;
}

.MuiTypography-root {
    font-family: iransans, sans-serif !important;
    font-size: 0.875rem !important;
    color: #656565 !important;
    border-right: 1px solid #565656;
    padding-right: 0.75rem !important;
    user-select: none;
}

.MuiAutocomplete-listbox {
    padding: 20px 12px !important;
    max-height: 246px !important;
    gap: 8px !important;
    font-size: 0.875rem !important;
    color: #656565 !important;
}

.MuiAutocomplete-option {
    padding: 12px 3px !important;
}

.MuiAutocomplete-option.Mui-focused {
    background-color: #b5dedd2e !important;
    color: #565656 !important;
}

.MuiAutocomplete-endAdornment {
    left: 14px !important;
    right: auto !important;
}

.rich-text-box .MuiTiptap-FieldContainer-notchedOutline {
    border-color: #c6c6c6 !important;
}

.rich-text-box .MuiTiptap-MenuBar-root {
    border: none !important;
}

.rmdp-arrow {
    border-color: #018780;
}

.rmdp-arrow-container:hover {
    background-color: #018780;
}

.rmdp-arrow-container:hover .rmdp-arrow {
    border-color: #fff;
}

.rmdp-week-day {
    color: #018780;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #018780;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: #018780a0;
}

.MuiFormControl-root:has(> * > .no-notch) .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.MuiFormControl-root:has(> * > .no-notch) > .MuiInputBase-root {
    padding-left: 0 !important;
    width: 110px;
    margin-left: -14px !important;
}

.MuiFormControl-root:has(> * > .no-notch) input {
    border-right: 1px solid #565656;
    padding-right: 12px !important;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.red-border .MuiTiptap-FieldContainer-notchedOutline {
    border-color: #d32f2f !important;
}

.list-page-table .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: row !important;
    justify-content: start !important;
    padding-right: 16px !important;
}

.list-page-table .MuiDataGrid-columnHeader--alignLeft .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: row !important;
    justify-content: end !important;
    padding-right: 16px !important;
}

.MuiAutocomplete-root:has(.MuiChip-root) .MuiInputBase-root {
    padding-left: 48px !important;
}

.custom-time-picker .Mui-selected {
    background: #b5dedd2e !important;
}

.MuiMenuItem-root {
    color: #565656 !important;
    font-size: 14px !important;
}

.zaman-container .zm-MonthYearButton {
    color: #3f3f46;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
}

.zaman-container .zm-MonthYearButton:hover,
.zaman-container .zm-MonthYearButton:visited,
.zaman-container .zm-MonthYearButton:focus-visible,
.zaman-container .zm-MonthYearButton:focus {
    background-color: #ecf8f6;
}

.zaman-container .zm-Header {
    background-color: white;
    border-bottom: #f4f4f5 1px solid;
    padding: 16px;
    height: auto;
}

.zaman-container .zm-IconPrevButton,
.zaman-container .zm-IconNextButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f3f46;
    background-color: white;
    border: 1px solid red;
    box-shadow: 0px 1px 5px -1px #110c221a;
    border: 1px solid #e4e4e7;
}

.zaman-container .zm-IconPrevButton:hover,
.zaman-container .zm-IconNextButton:hover,
.zaman-container .zm-IconPrevButton:visited,
.zaman-container .zm-IconNextButton:visited,
.zaman-container .zm-IconPrevButton:focus-visible,
.zaman-container .zm-IconNextButton:focus-visible,
.zaman-container .zm-IconPrevButton:focus,
.zaman-container .zm-IconNextButton:focus {
    background-color: #ecf8f6;
}

.zaman-container .zm-DaysButton:not([data-range='true']) {
    border-radius: 50%;
}

.zaman-container .zm-DaysButton[data-disabled='true'] {
    opacity: 0;
    pointer-events: none;
}
