.MuiDataGrid-columnHeaders {
    --DataGrid-containerBackground: #ecf8f6;
}

.MuiDataGrid-root {
    border: none !important;
}

.MuiDataGrid-row {
    background: white;
}

.css-1oudwrl::after {
    height: 0 !important;
}

.MuiDataGrid-cell {
    border: none !important;
}

.MuiDataGrid-scrollbar {
    right: auto !important;
    left: 0;
}

.MuiDataGrid-cell:focus {
    outline: solid #276a2a40 1px !important;
}

.MuiDataGrid-cell:focus-within {
    outline: solid #276a2a40 1px !important;
}

.MuiDataGrid-columnHeader:focus-within {
    outline: solid #276a2a40 1px !important;
}

.MuiDataGrid-row.Mui-selected {
    background: #a9cfcc !important;
}

.MuiDataGrid-row:hover {
    background-color: #cae4e1 !important;
}

.MuiDataGrid-iconButtonContainer {
    visibility: visible !important;
    width: auto !important;
}

.MuiDataGrid-columnHeaderTitleContainer:not(.add-data-grid *) {
    justify-content: center !important;
    background: #ecf8f6 !important;
}

.MuiDataGrid-columnHeader:not(.add-data-grid *:not(.no-header-padding *)) {
    padding: 0 !important;
}

.MuiDataGrid-scrollbarFiller--header {
    background: #ecf8f6 !important;
}

.MuiDataGrid-columnSeparator {
    display: none !important;
}

.MuiDataGrid-overlay {
    background: transparent !important;
}

.MuiDataGrid-filler {
    --DataGrid-rowBorderColor: transparent !important;
}

.MuiCircularProgress-svg {
    color: #006962 !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-size: 0.875rem !important;
    color: #565656 !important;
}

.MuiDataGrid-cell {
    font-size: 0.875rem !important;
    color: #565656 !important;
}

.MuiDataGrid-topContainer::after {
    height: 0 !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent:has(button) {
    width: 100% !important;
}

.MuiDialog-paper {
    box-shadow: none !important;
}

.MuiDataGrid-scrollbarFiller--borderTop {
    --DataGrid-rowBorderColor: transparent !important;
}
