.color-input .MuiFormControl-root {
    width: 100% !important;
}

.color-input .MuiInputAdornment-root button {
    overflow: hidden !important;
    border-radius: 50% !important;
    position: absolute !important;
    left: 16px !important;
}

.MuiSlider-colorPrimary .MuiSlider-thumb {
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}
