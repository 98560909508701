.add-data-grid .MuiDataGrid-columnHeaders {
    --DataGrid-containerBackground: #f3f2f7 !important;
}

.add-data-grid .MuiDataGrid-columnHeaderTitleContainer {
    background: #f3f2f7 !important;
}

.add-data-grid .MuiDataGrid-root {
    --unstable_DataGrid-radius: 0 !important;
}

.add-data-grid .MuiDataGrid-scrollbarFiller {
    --DataGrid-rowBorderColor: #fcfcfc !important;
}

.add-data-grid .MuiDataGrid-scrollbarFiller--header {
    background-color: #f3f2f7 !important;
}

.add-data-grid .MuiDataGrid-filler {
    background: #f3f2f7 !important;
}

.add-data-grid .MuiDataGrid-row,
.add-data-grid .MuiDataGrid-row:hover {
    background: #fcfcfc !important;
}

.add-data-grid .MuiDataGrid-columnSeparator {
    display: flex !important;
    visibility: visible !important;
}

.add-data-grid .MuiDataGrid-columnSeparator:first-of-type {
    display: none !important;
}

.add-data-grid .MuiDataGrid-columnHeaderTitle {
    color: #565656 !important;
    font-size: 0.875rem !important;
}

.add-data-grid .MuiDataGrid-cell {
    font-size: 0.75rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.MuiFormHelperText-root {
    height: 16px !important;
    line-height: 16px !important;
    padding: 0 !important;
    margin-top: 4px !important;
    margin-bottom: -4px !important;
    font-family: iransans, sans-serif !important;
}

.add-user-grid .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100% !important;
    padding: 45px;
}
