.MuiFormLabel-root {
    font-family: iransans, sans-serif !important;
    color: #777777 !important;
    font-size: 0.875rem;
}

.MuiTextField-root inputو.MuiTextField-root textarea {
    font-family: iransans, sans-serif !important;
    color: #565656 !important;
    font-size: 1rem;
}

.MuiFormLabel-root.Mui-focused {
    color: #777777 !important;
    font-weight: 600 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #c6c6c6 !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f !important;
}
