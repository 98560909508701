@tailwind components;



    @layer components {
        .page-header-style {
            @apply flex items-center justify-between border-b border-insight-300 mt-4 pb-6 gap-4;
            @apply flex items-center justify-between border-b border-insight-300 pb-4 pt-6 gap-4 sticky top-0 left-0 right-0 bg-insight-100 z-10;
        }

    .title-page-style {
        @apply w-full flex items-center justify-start text-lg text-insight-500 font-bold gap-4 ml-auto;
    }

    .new-link-page {
        @apply rounded-lg flex items-center justify-center gap-4 px-4 py-2 text-base text-white bg-blue-600 w-fit hover:bg-blue-800 disabled:bg-blue-300;
    }
}

@layer components {
    .search-in-table {
        @apply flex w-full flex-wrap gap-4 items-center justify-start mt-4;
    }

    .search-in-table-text {
        @apply text-base font-medium text-insight-500 min-w-52;
    }

    .search-in-table-input {
        @apply bg-white px-4 py-3 rounded-md min-w-72 outline-none border-none text-black shadow-md;
    }
}

@layer components {
    .loader {
        width: 2rem;
        height: 2rem;
        border: 5px solid green;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
